





























import {defineComponent, reactive, ref} from "@vue/composition-api";
import apis from "../../http/api";
import {Message} from "element-ui"
export default defineComponent({
  name: "Authorization",
  setup(props,ctx) { 
    const {$route:route} = ctx.root;
    const submitting = ref(false)
    const loginForm = reactive({
      userName:"",
      password:"",
    }) 
    const handleLogin = async ()=>{
      console.log(`提交登录表单`,loginForm);
      submitting.value= true;
      let {success,result,message} = await apis.employeeLogin(loginForm);
      console.log(`登录返回`,result)
      submitting.value = false;
      if(success) {
        Message.success("登录成功")
        const redirect = route.query.redirect;
        console.log(`回调地址`,redirect,`${redirect}?token=${result.token}`);
        window.location.replace(`${redirect}?token=${result.token}`) 
        
      }else{
        Message.error(message)
      }

   

    } 
    return {
      loginForm,handleLogin,submitting
    }

  },
  components: {  }
})
